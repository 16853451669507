import Component from '@ember/component';
import { computed } from '@ember/object';
import { PropTypes as T } from 'ember-prop-types';
import AssetsCatalog from 'gigshq/constants/assets-catalog';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';

export default Component.extend(Localizable(translations), {
  propTypes: {
    venue: T.nullable(T.object),
    offerType: T.string.isRequired,
    redemptionPeriod: T.string.isRequired,
    fineprint: T.nullable(T.string).isRequired,
    isReversedBlackout: T.bool.isRequired,
    blackoutDates: T.array,
    image: T.nullable(T.object),
    paused: T.bool.isRequired,
    deletedAt: T.nullable(T.string),
    venues: T.array,
    isDirty: T.bool.isRequired,
    isPending: T.bool.isRequired,
    offerId: T.nullable(T.string),
    data: T.nullable(T.object).isRequired,
    errors: T.nullable(T.array).isRequired,
    setFormValue: T.func.isRequired,
    setVenue: T.func.isRequired,
    setOfferImage: T.func.isRequired,
    save: T.func.isRequired,
    delete: T.func.isRequired,
  },

  offer: computed('deletedAt', function() {
    return this.getProperties('deletedAt');
  }),

  showVenueSelector: false,

  mascotState: computed('isDirty', 'errors', 'data', 'offerId', function() {
    if (!this.isDirty && this.offerId && this.data)
      return AssetsCatalog.GigiStates.THUMBS_UP.key;
    if (this.errors) return AssetsCatalog.GigiStates.PERPLEX.key;
    return AssetsCatalog.GigiStates.NEUTRAL.key;
  }),

  actionBoxText: computed('errors', function() {
    return this.localTranslation(
      this.errors ? 'action_box.error_text' : 'action_box.editing_text'
    );
  })
});
