import Component from '@ember/component';
import { computed } from '@ember/object';
import { notEmpty } from '@ember/object/computed';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';

export default Component.extend(Localizable(translations), {
  tagName: 'li',

  upcomingBlackoutDates: computed('offer.blackoutDates', function() {
    return this.offer.blackoutDates && this.offer.blackoutDates
      .map((date) => moment(date))
      .sort((a, b) => a - b)
      .filter((date) => date.isSameOrAfter(moment()));
  }),

  hasUpcomingBlackoutDates: notEmpty('upcomingBlackoutDates'),

  shouldShowItem: computed('offer.isReversedBlackout', 'hasUpcomingBlackoutDates', function() {
    if (this.offer.isReversedBlackout && this.hasUpcomingBlackoutDates)
      return true;

    if (!this.offer.isReversedBlackout)
      return true;

    return false;
  }),

  blackoutDatesDisplay: computed('upcomingBlackoutDates', function() {
    return this.upcomingBlackoutDates
      .map((date) => date.format('L'))
      .join(', ');
  }),

  offerImageUrl: computed('offer.offerType', function() {
    if (this.get('i18n.locale') === 'en') {
      if (this.offer.offerType === '10-for-20')
        return `/assets/images/venue-offers/10-for-20-en.jpg`;
      else if (this.offer.offerType === '10-for-25')
        return `/assets/images/venue-offers/10-for-25-en.jpg`;
      else if (this.offer.offerType === '10-for-30')
        return `/assets/images/venue-offers/10-for-30-en.jpg`;
      else if (this.offer.offerType === '20-for-40')
        return `/assets/images/venue-offers/20-for-40-en.jpg`;
      else if (this.offer.offerType === '20-for-50')
        return `/assets/images/venue-offers/20-for-50-en.jpg`;
      else if (this.offer.offerType === '20-for-60')
        return `/assets/images/venue-offers/20-for-60-en.jpg`;
    } else if (this.get('i18n.locale') === 'fr') {
      if (this.offer.offerType === '10-for-20')
        return `/assets/images/venue-offers/10-for-20-fr.jpg`;
      else if (this.offer.offerType === '10-for-25')
        return `/assets/images/venue-offers/10-for-25-fr.jpg`;
      else if (this.offer.offerType === '10-for-30')
        return `/assets/images/venue-offers/10-for-30-fr.jpg`;
      else if (this.offer.offerType === '20-for-40')
        return `/assets/images/venue-offers/20-for-40-fr.jpg`;
      else if (this.offer.offerType === '20-for-50')
        return `/assets/images/venue-offers/20-for-50-fr.jpg`;
      else if (this.offer.offerType === '20-for-60')
        return `/assets/images/venue-offers/20-for-60-fr.jpg`;
    }  }),
});
