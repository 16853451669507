import Component from '@ember/component';
import { computed } from '@ember/object';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';
import { colours } from 'gigshq/utils/chart-utils';
import moment from 'moment';

export default Component.extend(Localizable(translations), {
  chartData: computed('redemptions', function() {
    const ageData = [
      { age: 24, count: 0 },
      { age: 34, count: 0 },
      { age: 44, count: 0 },
      { age: 54, count: 0 },
      { age: 120, count: 0 },
    ];

    this.redemptions
      .map(redemption => moment().diff(redemption.user.member.birthdate, 'years'))
      .forEach(age => {
        let found = false;
        for (let i=0; !found && i < ageData.length; i++) {
          if (age <= ageData[i].age) {
            ageData[i].count++;
            found = true;
          }
        }
      });

    return {
      labels: ['18-24', '25-34', '35-44', '45-54', '55+'],
      datasets: [
        {
          label: 'Dataset 1',
          data: ageData.map(age => age.count),
          backgroundColor: colours(5),
        }
      ]
    };
  }),

  mailingList: computed('redemptions', function() {
    return this.redemptions.map(redemption => redemption.user.email)
      .filter((value, index, array) => array.indexOf(value) === index);
  }),

  actions: {
    getEmails() {
      const emailList = this.mailingList.reduce((accumulator, email) => accumulator + email + ';', '');
      navigator.clipboard.writeText(emailList);
      alert(this.localTranslation('success'));
    }
  }
});
